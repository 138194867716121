const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/#home',
  },
  {
    id: 2,
    name: 'About US',
    links: '/#about',
  },
  {
    id: 3,
    name: 'Services',
    links: '/#services',
  },
  {
    id: 4,
    name: 'Testimonials',
    links: '/#testimonials',
  },
  {
    id: 5,
    name: 'Contact',
    links: '/#contact',
  },
]

export default menus
