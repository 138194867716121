import Home01 from './Home01'
import Services from './Services'


const routes = [
    { path: '/', component: Home01},
    { path: '/services', component: Services},

]

export default routes;