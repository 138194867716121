import React, { Component } from 'react';

import slide1 from '../../../assets/services/1.jpeg'
import slide2 from '../../../assets/services/2.jpeg'
import slide3 from '../../../assets/services/3.png'
import slide4 from '../../../assets/services/4.jpeg'
import slide5 from '../../../assets/services/5.jpeg'
import slide6 from '../../../assets/services/6.jpeg'
import slide7 from '../../../assets/services/7.jpg'
import slide8 from '../../../assets/services/8.jpg'
import slide9 from '../../../assets/services/9.jpg'
import slide10 from '../../../assets/services/10.jpg'
import slide11 from '../../../assets/services/11.jpg'
import slide12 from '../../../assets/services/12.jpg'
import slide13 from '../../../assets/services/13.jpg'
import slide14 from '../../../assets/services/14.jpg'
import slide15 from '../../../assets/services/15.jpg'


class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partner: [
                {
                    id: 1,
                    img: slide1,
                    imgcolor: slide1
                },
                {
                    id: 2,
                    img: slide2,
                    imgcolor: slide2
                },
                {
                    id: 3,
                    img: slide3,
                    imgcolor: slide3
                },
                {
                    id: 4,
                    img: slide4,
                    imgcolor: slide4
                },
                {
                    id: 5,
                    img: slide5,
                    imgcolor: slide5
                },
                {
                    id: 6,
                    img: slide6,
                    imgcolor: slide6
                },
                {
                    id: 7,
                    img: 'assets/img/partners/partner-2.png',
                    imgcolor: 'assets/img/partners/partner-2-color.png'
                },
                {
                    id: 8,
                    img: 'assets/img/partners/partner-3.png',
                    imgcolor: 'assets/img/partners/partner-3-color.png'
                },
                {
                    id: 9,
                    img: 'assets/img/partners/partner-4.png',
                    imgcolor: 'assets/img/partners/partner-4-color.png'
                },
                {
                    id: 10,
                    img: 'assets/img/partners/partner-5.png',
                    imgcolor: 'assets/img/partners/partner-5-color.png'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row-partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={83} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-carousel-box clearfix" data-gap={5} data-column={5} data-column2={3}
                                data-column3={2} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    {
                                        this.state.partner.map(data =>(
                                            <div className="themesflat-partner style-1 align-center clearfix" key={data.id}>
                                                <div className="partner-item">
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={data.img} alt="altimage"
                                                                className="partner-default" />
                                                            <img src={data.imgcolor} alt="altimage"
                                                                className="partner-color" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={66} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Partner;