import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

// img
import slide1 from '../../../assets/slider-1.jpg'
import slide2 from '../../../assets/slider-2.jpg'
import slide3 from '../../../assets/slider-3.jpg'

class Slider extends Component {
  render() {
    const bannerhome1 = [
      {
        id: 1,
        srcimg: slide1,
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption',
        title: 'BUILT FROM INTEGRITY.',
        disc1: 'WE WILL BE HAPPY',
        disc2: 'TO TAKE CARE OF YOUR Home',
      },
      {
        id: 2,
        srcimg: slide2,
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['center','center','center','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption text-center',
        title: 'BUILT FROM VISION.',
        disc1: 'We bring your',
        disc2: 'dream project into reality.',
      },
      {
        id: 3,
        srcimg: slide3,
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
        datax: "['right','right','right','center']",
        datahset: "['-14','-14','-14','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption text-right',
        title: 'BUILT FROM ECCENTRICITY.',
        disc1: 'Architecturally designed,',
        disc2: 'innovative, and unique',
      },
    ]

    const { location } = this.props
    const mainContent = () => {
      if (location.pathname === '/') {
        return (
          <>
            {bannerhome1.map((data) => (
              <li data-transition='random' key={data.id}>
                <img src={data.srcimg} alt='altimage' data-bgposition='center center' data-no-retina />
                <div className={data.classnames} data-x={data.datax} data-hoffset={data.datahset} data-y="['middle','middle','middle','middle']" data-voffset={data.datavset1} data-fontsize="['20','20','20','16']" data-lineheight="['70','70','40','24']" data-width='full' data-height='none' data-whitespace='normal' data-transform_idle='o:1;' data-transform_in='y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;' data-transform_out='y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;' data-mask_in='x:0px;y:[100%];' data-mask_out='x:inherit;y:inherit;' data-start={700} data-splitin='none' data-splitout='none' data-responsive_offset='on'>
                  {data.title}
                </div>
                <div>
                  <div className={data.classtext} data-x={data.datax} data-hoffset={data.datahset} data-y="['middle','middle','middle','middle']" data-voffset={data.datavset2} data-fontsize="['52','52','42','32']" data-lineheight="['65','65','45','35']" data-width='full' data-height='none' data-whitespace='normal' data-transform_idle='o:1;' data-transform_in='y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;' data-transform_out='y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;' data-mask_in='x:0px;y:[100%];' data-mask_out='x:inherit;y:inherit;' data-start={1000} data-splitin='none' data-splitout='none' data-responsive_offset='on'>
                    {data.disc1}
                  </div>
                  <div className={data.classtext} data-x={data.datax} data-hoffset={data.datahset} data-y="['middle','middle','middle','middle']" data-voffset={data.datavset3} data-fontsize="['52','52','42','32']" data-lineheight="['65','65','45','35']" data-width='full' data-height='none' data-whitespace='normal' data-transform_idle='o:1;' data-transform_in='y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;' data-transform_out='y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;' data-mask_in='x:0px;y:[100%];' data-mask_out='x:inherit;y:inherit;' data-start={1000} data-splitin='none' data-splitout='none' data-responsive_offset='on'>
                    {data.disc2}
                  </div>
                </div>
                <div id='contact-cont' className={data.classbtn} data-x={data.datax} data-hoffset={data.datahset} data-y="['middle','middle','middle','middle']" data-voffset={data.datavset} data-width='full' data-height='none' data-whitespace='normal' data-transform_idle='o:1;' data-transform_in='y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;' data-transform_out='y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;' data-mask_in='x:0px;y:[100%];' data-mask_out='x:inherit;y:inherit;' data-start={1000} data-splitin='none' data-splitout='none' data-responsive_offset='on'>
                  <Link
                    id='contact-link'
                    to='#contact'
                    onClick={() => {
                      window.location.href = '#contact'
                    }}
                    className='themesflat-button bg-accent big'
                  >
                    <span>GET IN TOUCH</span>
                  </Link>
                </div>
              </li>
            ))}
          </>
        )
      }
    }
    return <ul>{mainContent()}</ul>
  }
}

export default withRouter(Slider)
