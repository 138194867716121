import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../assets/logo-small@2x.png'

class Footer extends Component {
  render() {
    return (
      <footer id='footer' className='clearfix'>
        <div id='footer-widgets' className='container'>
          {/* <div className='themesflat-row gutter-30'>
            <div className='widget widget_text'>
              <div className='textwidget' style={{"display":"flex","justifyContent":"space-around"}}>
                <p>
                  <img src={logo} alt='imagealt' width={170} height={34} />
                </p>
                

                <div className='inner'>
                  <span className='fa fa-map-marker' />
                  <span className='text'>
                    10 bridletowne cricle, Toronto ON <span className='sl'>Toronto, ON</span>
                  </span>
                </div>

                <div className='inner'>
                  <span className='fa fa-phone' />
                  <span className='text'>CALL US : +1 647-720-8770</span>
                </div>

                <div className='inner'>
                  <span className=' font-size-14 fa fa-envelope' />
                  <span className='text'>info@tgrconstruction.ca</span>
                </div>
              </div>
            </div>
            <div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={0} data-smobile={35} />


          </div> */}
          <div className='bottom-bar-menu'>
            <ul className='bottom-nav'>
              <div className='inner'>
                <span className='fa fa-map-marker' />
                <span className='text'>
                  10 bridletowne cricle, Toronto ON <span className='sl'>Toronto, ON</span>
                </span>
              </div>

              <div className='inner'>
                <span className='fa fa-phone' />
                <span className='text'>CALL US : +1 647-720-8770</span>
              </div>

              <div className='inner'>
                <span className=' font-size-14 fa fa-envelope' />
                <span className='text'>info@tgrconstruction.ca</span>
              </div>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
