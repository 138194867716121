import React, { Component } from 'react';

class ServiceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                {
                    id: 1,
                    text: '',
                    item: [
                    
                        {
                            idx: 1,
                            content: 'Bathroom',
                        },
                        {
                            idx: 2,
                            content: 'Kitchen',
                        },
                        {
                            idx: 3,
                            content: 'Garage',
                        },
                        {
                            idx: 4,
                            content: 'Commercial',
                        },
  
                    ]
                },
                {
                    id: 2,
                    text: '',
                    item: [
                       
                        {
                            idx: 1,
                            content: 'Caulking',
                        },
                        {
                            idx: 2,
                            content: 'Demolition',
                        },
                        {
                            idx: 3,
                            content: 'Flooring',
                        },
                        {
                            idx: 4,
                            content: 'Insulation',
                        },
                        {
                            idx: 5,
                            content: 'Painting',
                        },
                        {
                            idx: 6,
                            content: 'Sound Proofing',
                        },
                        {
                            idx: 7,
                            content: 'Woodworking',
                        },
                    ]
                },
                {
                    id: 3,
                    text: '',
                    item: [
                      
                        {
                            idx: 1,
                            content: 'Balcony',
                        },
                        {
                            idx: 2,
                            content: 'Windows/Doors',
                        },
                        {
                            idx: 3,
                            content: 'Driveway Sealant',
                        },
                        {
                            idx: 4,
                            content: 'Fence',
                        }
                    ]
                },

                

            ]
        }
    }
    
    render() {
        return (
            <div className="col-md-8">
                <div className="themesflat-content-box clearfix" data-margin="0 18px 0 0" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-headings style-1 clearfix">
                        <h2 className="heading">OVERVIEW</h2>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-tabs style-1 clearfix">
                        <ul className="tab-title clearfix">
                            <li className="item-title active">
                                <span className="inner">General Contractor</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Renovation</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Exterior Renovation</span>
                            </li>
                        </ul>
                        <div className="tab-content-wrap clearfix">
                            {
                                this.state.tab.map(data => (
                                    <div className="tab-content" key={data.id}>
                                        <div className="item-content">
                                            <p>{data.text}</p>
                                            {
                                                data.item.map(index => (
                                                    <div className="themesflat-list has-icon style-1 icon-left ofset-padding-32 clearfix" key={index.idx}>
                                                        <div className="inner">
                                                            <span className="item">
                                                                <span className="icon"><i className="fa fa-check-square" /></span>
                                                                <span className="text">{index.content}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>{/* /.tab-content-wrap */}
                    </div>{/* /.themesflat-tabs */}
                </div>{/* /.themesflat-content-box */}
            </div>
        );
    }
}

export default ServiceListing;