import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import slide1 from '../../../assets/services/1.jpeg'
import slide2 from '../../../assets/services/2.jpeg'
import slide3 from '../../../assets/services/3.png'
import slide4 from '../../../assets/services/4.jpeg'
import slide5 from '../../../assets/services/5.jpeg'
import slide6 from '../../../assets/services/6.jpeg'
import slide7 from '../../../assets/services/7.jpg'
import slide8 from '../../../assets/services/8.jpg'
import slide9 from '../../../assets/services/9.jpg'
import slide10 from '../../../assets/services/10.jpg'
import slide11 from '../../../assets/services/11.jpg'
import slide12 from '../../../assets/services/12.jpg'
import slide13 from '../../../assets/services/13.jpg'
import slide14 from '../../../assets/services/14.jpg'
import slide15 from '../../../assets/services/15.jpg'
import ServiceListing from './ServiceListing';


class ServiceSlide extends Component {
    render() {
        return (
            <div className="row-services">
                <div className="container">

                    <ServiceListing />

                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                
                                <div className="owl-carousel owl-theme">
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src= {slide1} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide14} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
         
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide4} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
        
                                            </div>
                                        </div>
                                    </div>
                
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={15} data-mobile={15} data-smobile={15} />
                            

                            {/* Second */}
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide5} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide6} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide7} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                           
                            <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={60} data-smobile={60} />
                        
                        
                        
                            {/* Second */}
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide8} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide10} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide11} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        
                            <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={60} data-smobile={60} />
                            
                            {/* Second */}
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide12} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide13} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src={slide15} alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        
                            <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={60} data-smobile={60} />
                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSlide;